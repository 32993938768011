






















































































import { Role } from '@/api/auth/role.enum';
import Vue from 'vue';
import Component from 'vue-class-component';

import { namespace } from 'vuex-class';
import { LoginResult } from '../api/auth/login-result.class';
import { Login } from '../api/auth/login.class';

const authModule = namespace('auth');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class LoginView extends Vue {
  loginData: Login = new Login(
    process.env.VUE_APP_LOGIN,
    process.env.VUE_APP_PASSWORD,
  );

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @authModule.Action('login')
  login!: (login: Login) => Promise<LoginResult>;

  async submitLogin() {
    this.addLoader('login');
    try {
      const loggedIn = await this.login(this.loginData);
      if (!loggedIn) {
        return;
      }
      if (
        loggedIn.user.projects?.length > 1 ||
        loggedIn.user.role === Role.SuperAdmin ||
        loggedIn.user.role === Role.CompanyAdmin
      ) {
        this.$router.push({ name: 'SelectProject' });
      } else {
        this.$router.push({ path: '/' });
      }
    } finally {
      this.removeLoader('login');
    }
  }
}
